<template>
  <div class="flex flex-wrap">
    <div
      v-for="(ad, index) in Object.keys(field)"
      :key="ad"
      class="w-full lg:w-1/2 mb-2"
      :class="{
        'lg:pr-4': index % 2 == 0,
        'lg:pl-4': index % 2 != 0
      }"
    >
      <label
        for=""
        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
      >
        {{ ad }}
      </label>
      <input
        v-model="field[ad]"
        type="text"
        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressInput",
  props: {
    field: {
      type: Object,
      require: true
    }
  }
};
</script>
