<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 rounded-lg bg-blueGray-100 border-0"
    :class="{
      'shadow-lg': hasShadow
    }"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6" v-if="haveHeader">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">
          <i class="fas fa-arrow-left cursor-pointer mr-3" @click="lastPage" />
          {{ $t(formName) }}
          <slot name="aftername" />
        </h6>
        <slot name="header" />
      </div>
    </div>
    <div v-if="sectionItems.length > 0">
      <div class="px-8 pt-3 flex text-gray-400 font-semibold">
        <slot name="mainAreaHeader" />
      </div>
      <div class="border-b px-6 flex">
        <div
          class="mx-2 pt-2 text-gray-500 cursor-pointer font-semibold"
          v-for="sectionItem in [{ label: 'general' }].concat(sectionItems)"
          :key="sectionItem.label"
          @click="selectTab(sectionItem)"
          :class="{
            'border-b-4 pb-1 border-gray-500': currentTab == sectionItem.label
          }"
        >
          {{ $t(sectionItem.label) }}
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <slot name="mainArea" />
      <form v-if="currentTab == 'general'" autocomplete="chrome-off">
        <div
          v-for="({ dispatch, section, fields, slotName }, index) in form"
          :key="index"
          class="mt-6 border-blueGray-300"
          :class="{ 'border-t': index != 0 }"
        >
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            {{ $t(section) }}
          </h6>
          <slot class="flex flex-wrap" v-if="slotName" :name="slotName" />
          <div class="flex flex-wrap" v-else>
            <template v-for="(field, j) in fields">
              <div
                v-if="field.type != 'hidden'"
                class="w-full px-4 mt-3"
                :key="j"
                :class="{
                  'lg:w-12/12': field.type == 'textarea',
                  'lg:w-12/12':
                    field.width == 'full' || field.type == 'address',
                  'lg:w-6/12':
                    field.type != 'textarea' &&
                    field.width != 'full' &&
                    field.type != 'address'
                }"
              >
                <!-- without the complciated key vue doenst update reactively -->
                <AddressInput
                  v-if="field.type == 'address'"
                  :key="`${j}-address`"
                  :field="field.value"
                />
                <div v-else class="relative w-full">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    v-if="field.type != 'hidden' && field.type != 'checkbox'"
                  >
                    {{ $t(field.name) }}
                    <i
                      class="fas fa-asterisk text-rose-500"
                      v-if="field.required"
                    />
                  </label>
                  <VaultDateTime
                    :key="`vaultDatetime-${index}- ${
                      field.value && field.value.endTime
                        ? field.value.endTime
                        : '-1'
                    }-${
                      field.value && field.value.startTime
                        ? field.value.startTime
                        : '-2'
                    }`"
                    v-if="field.type == 'timerange'"
                    :field="field"
                  />
                  <el-date-picker
                    class="w-full"
                    style="width: 100%"
                    v-if="field.type == 'datetime'"
                    v-model="field.value"
                    type="datetime"
                    :placeholder="$t('Pick a date and time')"
                  />
                  <div
                    v-if="field.type == 'checkbox'"
                    @click="field.value = !field.value"
                    class="cursor-pointer flex items-center"
                  >
                    <i
                      v-if="field.value == true"
                      class="fas fa-check-square text-indigo-600 text-2xl"
                    />
                    <i
                      v-if="field.value == false"
                      class="far fa-square text-indigo-600 text-2xl"
                    />
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold ml-2"
                    >
                      {{ $t(field.name) }}
                    </label>
                  </div>
                  <input
                    v-if="field.type == 'readonly'"
                    v-model="field.value"
                    type="text"
                    disabled
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 bg-gray-100 rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  />
                  <div class="mb-10" v-if="field.type == 'upload'">
                    <img v-if="field.path" class="mb-3" :src="field.path" />
                    <input
                      id="uploadFile-company-logo"
                      type="file"
                      :ref="field.action"
                      @change="fileChange(field)"
                      accept="image/*"
                      hidden
                    />
                    <label
                      class="bg-emerald-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      for="uploadFile-company-logo"
                    >
                      {{ $t("upload logo") }}
                    </label>
                    <label
                      v-if="field.path"
                      class="bg-rose-500 cursor-pointer text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      @click="field.removeFile"
                    >
                      {{ $t("remove") }}
                    </label>
                  </div>
                  <input
                    v-if="field.type == 'email'"
                    v-model="field.value"
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  />
                  <div class="mt-4" v-if="field.type == 'switch'">
                    <el-switch v-model="field.value" />
                  </div>
                  <div
                    class="flex justify-center items-center bg-white shadow rounded px-1"
                    v-if="field.type == 'password'"
                  >
                    <input
                      v-model="field.value"
                      :type="field.showPassword ? 'text' : 'password'"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 rounded text-sm focus:outline-none w-full"
                    />
                    <i
                      class="fas text-blueGray-500 mr-2 cursor-pointer hover:text-blueGray-400"
                      :class="{
                        'fa-eye-slash': field.showPassword,
                        'fa-eye': !field.showPassword
                      }"
                      @click="field.showPassword = !field.showPassword"
                    />
                  </div>

                  <el-date-picker
                    class="w-full"
                    v-if="field.type == 'datepicker'"
                    v-model="field.value"
                    type="date"
                    :placeholder="$t('Pick a day')"
                  />
                  <el-select
                    clearable
                    class="w-full"
                    v-model="field.value"
                    placeholder="Select"
                    v-if="field.type == 'select'"
                    filterable
                  >
                    <el-option
                      v-for="item in field.options"
                      :key="item.id"
                      :label="`${item[field.optionLabelKey]}`"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    clearable
                    class="w-full"
                    v-model="field.value"
                    placeholder="Select"
                    v-if="field.type == 'selectByOptionProp'"
                    :disabled="field.lock == true"
                    filterable
                  >
                    <el-option
                      v-for="item in options[field.options]"
                      :key="item.id"
                      :label="`${item[field.optionLabelKey]}`"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    clearable
                    class="w-full"
                    v-model="field.value"
                    placeholder="Select"
                    v-if="field.type == 'customSelect'"
                    filterable
                  >
                    <el-option
                      v-for="item in field.options"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                  <el-select
                    clearable
                    v-model="field.value"
                    multiple
                    collapse-tags
                    placeholder="Select"
                    v-if="field.type == 'multiselect'"
                    filterable
                  >
                    <el-option
                      v-for="item in field.options"
                      :key="item.id"
                      :label="`${item[field.optionLabelKey]}`"
                      :value="item.id"
                    />
                  </el-select>
                  <el-select
                    clearable
                    class="w-full"
                    v-model="field.value"
                    multiple
                    collapse-tags
                    placeholder="Select"
                    v-if="field.type == 'multiselectByOptionProp'"
                    filterable
                  >
                    <el-option
                      v-for="item in options[field.options]"
                      :key="item.id"
                      :label="`${item[field.optionLabelKey]}`"
                      :value="item.id"
                    />
                  </el-select>
                  <input
                    v-if="field.type == 'text'"
                    v-model="field.value"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  />
                  <div
                    class="bg-white flex items-center shadow rounded"
                    v-if="field.type == 'number'"
                  >
                    <input
                      v-model="field.value"
                      type="number"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm focus:outline-none w-full"
                    />
                    <span
                      class="mx-2 font-semibold text-gray-400"
                      v-if="field.unit"
                    >
                      [{{ field.unit }}]
                    </span>
                  </div>
                  <Editor
                    v-if="field.type == 'textareaEditor'"
                    :body="field.value"
                    v-model="field.value"
                  />
                  <textarea
                    v-if="field.type == 'textarea'"
                    v-model="field.value"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                    rows="4"
                  />
                </div>
                <span
                  v-if="field.required && valid == false && field.value == ''"
                  class="text-xs text-rose-600 mb-2 -mt-2"
                >
                  {{ $t("this field is required") }}
                </span>
              </div>
            </template>
          </div>
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs ml-4 mt-6 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            @click="submit(dispatch, fields)"
          >
            {{ $t("save") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Editor from "./Editor.vue";
import AddressInput from "./formComponents/AddressInput.vue";
import VaultDateTime from "./formComponents/VaultDateTime.vue";
export default {
  name: "Form",
  props: {
    haveHeader: {
      type: Boolean,
      default: true
    },
    hasShadow: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default: () => {}
    },
    formName: {
      type: String,
      defualt: ""
    },
    sectionItems: {
      type: Array,
      default: () => []
    },
    clearForm: {
      type: Boolean,
      defualt: false
    },
    form: {
      type: Array,
      default: () => []
    },
    lastPageName: {
      type: String,
      default: ""
    },
    currentTab: {
      type: String,
      default: "general"
    }
  },
  components: { Editor, AddressInput, VaultDateTime },
  data() {
    return {
      valid: true
    };
  },
  methods: {
    fileChange({ action, cb }) {
      let formData = new FormData();
      const f = this.$refs?.[action]?.[0]?.files?.[0];
      if (f) {
        formData.append("file", f);
        formData.append(`action`, action);
        this.$store.dispatch("upload/upload", {
          payload: formData,
          cb
        });
      }
    },
    selectTab(item) {
      this.$emit("selectTab", item.label);
    },
    async submit(dispatch, fields) {
      if (dispatch) {
        this.valid = true;
        let data = fields.reduce(
          (accu, { parentKey, key, value, isCustomData, required }) => {
            if (
              required == true &&
              (value == "" || value == null || value == undefined)
            ) {
              this.valid = false;
            }
            if (isCustomData) {
              accu.custom_data[key] = value;
            } else if (parentKey && accu[parentKey]) {
              accu[parentKey][key] = value;
            } else if (parentKey) {
              accu[parentKey] = {
                [key]: value
              };
            } else {
              accu[key] = value;
            }
            return accu;
          },
          { custom_data: {} }
        );
        if (this.valid) {
          const res = await this.$store.dispatch(dispatch, data);
          if (res) {
            this.$emit("done");
          }
        } else {
          this.$notify.error({
            title: this.$t("Error"),
            message: this.$t("please fix all field errors")
          });
        }
      }
    },
    lastPage() {
      // const name =
      //   this.lastPageName != ""
      //     ? this.lastPageName
      //     : this.$route.name.replace("-Detail", "");
      // this.$router.push({ name });
      this.$router.back();
    }
  }
};
</script>

<style>
.el-date-editor.w-full.el-input.el-input--prefix.el-input--suffix.el-date-editor--date {
  width: 100%;
}
</style>
