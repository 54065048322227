<template>
  <!-- <div> -->
  <ckeditor
    v-model="editorData"
    :config="editorConfig"
    @input="$emit('input', editorData)"
  />
  <!-- </div> -->
</template>

<script>
import CKEditor from "ckeditor4-vue";
export default {
  name: "Editor",
  props: {
    body: {
      type: String,
      required: true
    }
  },
  components: {
    ckeditor: CKEditor.component
  },
  watch: {
    body() {
      this.editorData = this.body;
    }
  },
  data() {
    return {
      editorData: "",
      editorConfig: {
        toolbarGroups: [
          {
            name: "basicstyles",
            groups: ["basicstyles"]
          },
          {
            name: "links",
            groups: ["links"]
          },
          {
            name: "paragraph",
            groups: ["list"]
          },
          {
            name: "document",
            groups: ["mode"]
          },
          {
            name: "styles",
            groups: ["styles"]
          }
        ],
        // Remove the redundant buttons from toolbar groups defined above.
        removeButtons:
          // "Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar"
          "Subscript,Superscript,Anchor,Styles,Specialchar"
      }
    };
  },
  created() {
    this.editorData = this.body;
  }
};
</script>
