<template>
  <div class="flex justify-between items-center">
    <el-time-picker
      value-format="HH:mm"
      format="HH:mm"
      placeholder="Start time"
      v-model="startTime"
      :picker-options="{
        format: 'HH:mm',
        minTime: startTime
      }"
    />
    <i class="fas fa-minus text-xs" />
    <el-time-picker
      value-format="HH:mm"
      format="HH:mm"
      placeholder="End time"
      v-model="endTime"
      :picker-options="{
        format: 'HH:mm',
        minTime: startTime
      }"
    />
  </div>
</template>

<script>
export default {
  name: "VaultDateTime",
  props: {
    field: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      startTime: "",
      endTime: ""
    };
  },
  watch: {
    field: {
      deep: true,
      handler() {
        this.startTime = this.field.value.startTime;
        this.endTime = this.field.value.endTime;
      }
    },
    startTime() {
      this.field.value.startTime = this.startTime;
    },
    endTime() {
      this.field.value.endTime = this.endTime;
    }
  },
  created() {
    this.startTime = this.field.value.startTime;
    this.endTime = this.field.value.endTime;
  }
};
</script>
